/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Image } from '@swp/components'


export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"Kalendář Akcí"}>
        <Column className="pt--60" style={{"backgroundColor":"#fdf3b6","paddingBottom":0}} layout={"l8"} name={"tk10rhvdkwg"}>
          
          <ColumnWrap className="column__flex --center el--3" style={{"backgroundColor":"rgba(253,243,182,1)"}} columns={"3"}>
            
            <ColumnWrapper >
            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/13139/dc65ca8aad0744d6bfa4e901c54ad005_s=860x_.png"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/13139/dc65ca8aad0744d6bfa4e901c54ad005_s=350x_.png 350w, https://cdn.swbpg.com/t/13139/dc65ca8aad0744d6bfa4e901c54ad005_s=660x_.png 660w, https://cdn.swbpg.com/t/13139/dc65ca8aad0744d6bfa4e901c54ad005_s=860x_.png 860w, https://cdn.swbpg.com/t/13139/dc65ca8aad0744d6bfa4e901c54ad005_s=1400x_.png 1400w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper >
            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" style={{"backgroundColor":"#fdf3b6"}} layout={"l8"} name={"tk10rhvdkwg"}>
          
          <ColumnWrap className="column__flex --center el--3" style={{"backgroundColor":"rgba(253,243,182,1)"}} columns={"3"}>
            
            <ColumnWrapper >
            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/13139/caec4b5239a7490db52a507ce72db562_s=860x_.png"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/13139/caec4b5239a7490db52a507ce72db562_s=350x_.png 350w, https://cdn.swbpg.com/t/13139/caec4b5239a7490db52a507ce72db562_s=660x_.png 660w, https://cdn.swbpg.com/t/13139/caec4b5239a7490db52a507ce72db562_s=860x_.png 860w, https://cdn.swbpg.com/t/13139/caec4b5239a7490db52a507ce72db562_s=1400x_.png 1400w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper >
            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}